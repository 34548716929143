import React, { useState } from 'react';
import Layout from '../../../components/Layout';

const FAQ = () => {
  return (
    <Layout fullMenu logoUrl="/FL/Cosmetology">
      <article id="main">
        <header>
          <h2>Frequently Asked Questions</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h4>Does AdvanCE offer group discounts?</h4>
            <p>We offer discounts to organizations that have 5 or more individuals looking to complete our courses.</p>
            <h4>How do I create an account?</h4>
            <p>
              You can use the “Sign In” option in the top right of the page to create an account, or sign into an existing account. You may
              also create an account after successfully passing a test.
            </p>
            <h4>When will I receive my certificate of completion?</h4>
            <p>Your certificate will be emailed to you instantly upon completion of the course</p>
            <h4>If I lost my certificate, how do I receive another copy?</h4>
            <p>
              Sign into your account, then go to your user dashboard. All course(s) that you have completed will be displayed and you can
              email yourself a copy whenever you’d like.
            </p>

            <h4>When do I have to complete my CE requirements by?</h4>
            <p>
              Florida cosmetologists, Nail Specialists, Facial Specialists and Full Specialists are required to complete their requirements
              by October 31st.
            </p>

            <h4>How many hours am I required to complete to renew my license?</h4>
            <p>Professionals licensed by the Florida Board of Cosmetology must complete 10 continuing education hours every 2 years.</p>

            <h4>Does AdvanCE report my completed credits to the state?</h4>
            <p>AdvanCE uploads all course completion records within 2 business days of completion.</p>

            <h4>Do I have to keep my certificate after I complete the course?</h4>
            <p>
              You are responsible for retaining for four years all receipts, certificates, and other documents as necessary to show
              completion of CE, which you will have to produce if you are audited. AdvanCE will also keep a copy of your certificate(s) for
              4 years after the completion date.
            </p>

            <h4>I am in school but haven’t received my license yet, are there any requirements?</h4>
            <p>Initial licensees must complete a 4-Hour HIV/AIDS course.</p>

            <h4>Does AdvanCE offer CE for other states?</h4>
            <p>
              We’re currently offering continuing education for Professional Cosmetologists in Florida. We do however, plan on entering
              additional markets in the future.
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default FAQ;
